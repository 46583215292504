import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'

import Hero from '../sections/Hero/Hero'
import Showcase from '../sections/Showcase/Showcase'
import Highlights from '../sections/Highlights/Highlights'
import Connect from '../sections/Connect/Connect'
import Try from '../sections/Try/Try'
import RequestDemo from '../sections/RequestDemo/RequestDemo'

const DistibutedTeams = () => {
  return (
    <Layout pageName="distributed-teams">
      <Seo />
      <Container>
        <Hero
          heading="Get team transparency back"
          subheading="Have full visibility on the progress, identify bottlenecks, unblock your teams."
          group="Engineers"
          features={[
            'Alerts',
            'Notifications',
            'Dashboards',
            'Activity feed',
            'Scheduled reports',
            'Surveys'
          ]}
          imageName="hero-green.png"
          isSolutions
        />

        <Showcase imageName="distributed-teams-showcase" />

        <RequestDemo />

        <Highlights
          items={[
            {
              heading: 'Activities feed helps you increases transparency',
              subheading:
                'Activities feed brings together important actions related to your team.',
              imageName: 'manager-highlights-1.png',
              imageMobileName: 'home-highlights-1-mobile.png'
            },
            {
              heading: 'Ally provides visibility from idea to production',
              subheading:
                'Fully-customizable dashboards enable you to diagnose and act on team issues',
              imageName: 'manager-highlights-2.png',
              imageMobileName: 'engineer-highlights-1-mobile.png'
            },
            {
              heading: 'Keep an eye on the progress',
              subheading:
                'Scheduled reports delivered to your chat platforms. Help your team to move work forward.',
              imageName: 'manager-highlights-3.png',
              imageMobileName: 'engineers-highlights-2-mobile.png'
            },
            {
              heading: 'Alerts help you stay on top of your day',
              subheading:
                'Receive alerts in Slack or Microsoft Teams when something has or has not happened.',
              imageName: 'manager-highlights-4.png',
              imageMobileName: 'engineers-highlights-3-mobile.png'
            },
            {
              heading: 'Do not miss anything important with notifications',
              subheading:
                'Extended filters and configurations enable you reduce noise and do not miss important events. Get notifications delivered to your chat platform.',
              imageName: 'manager-highlights-2.png',
              imageMobileName: 'engineer-highlights-1-mobile.png'
            },
            {
              heading: 'Combine data from surveys and tools',
              subheading: 'Run surveys and combine results with events and data from connected tools like GitHub, Jira or Slack.',
              imageName: 'daily-team-survey.png',
              imageMobileName: 'daily-team-survey.png'
            },
          ]}
        />

        <Connect />

        <Try isSolutions />
      </Container>
    </Layout>
  )
}

export default DistibutedTeams
